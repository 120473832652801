import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Sezione from "../components/sezione"
import SEO from "../components/seo"
import BanneronePage from "../components/banneronePage"
import TitoloPagina from "../components/titoloPagina"
import CardPagina from "../components/cardPagina"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import BottoneBianco from "../components/bottoneBianco"

const SolareTermodinamico = () => (
  <Layout isPagina={true}>
    <SEO
      title="Impianto solare termodinamico per la produzione di Acqua calda con pompa di calore"
      description="Realizzazione di impianti solari termodinamici per la produzione di acqua calda con pompa di calore in tutto il Lazio"
    />
    <BanneronePage title="Solare Termodinamico" />
    <Container className="mt-4 mb-4">
      <TitoloPagina title="Impianto solare termodinamico per la produzione di Acqua Calda con pompa di calore Lazio: Roma, Pomezia, Aprilia, Latina, Anzio, Nettuno" />
      <Row>
        <Col className="h-100" md={8}>
          <CardPagina variante="bordoLeft">
            <p>
              <strong>
                Funzionamento e vantaggi del sistema SOLARE TERMODINAMICO
              </strong>
              <br />I sistemi solari termodinamici proposti da NSE srl
              rappresentano la terza generazione degli impianti solari termici
              per la produzione di acqua calda. Tali sistemi sono in grado di
              captare il calore dal sole e perfino dalla pioggia e dal vento, 24
              ore al giorno, 365 giorni all’anno. Uno degli aspetti innovativi
              risiede nel fatto che in essi circola un fluido termovettore
              ecologico a temperature negative (-10/-20°C), permettendo una
              maggiore captazione dell’energia solare ed un maggior assorbimento
              dell’energia ambiente che, successivamente, è ceduta attraverso un
              scambiatore di calore per produrre acqua calda sanitaria.
            </p>
            <p>
              In particolare, la differenza di temperatura causata dagli agenti
              atmosferici esterni garantisce che il fluido ecologico
              (temperatura di ebollizione a -30°C), che è presente nel pannello
              termodinamico, si trasformi in gas ed evapori all’interno del
              pannello. Dopo la sua evaporazione, il fluido è aspirato dal
              compressore, che aumenta la sua temperatura e la sua pressione. A
              sua volta, il calore generato al momento della compressione viene
              trasferito all’acqua sanitaria attraverso lo scambiatore di calore
              presente nel boiler. Prima che il fluido ecologico torni al
              pannello solare, è necessario che si verifichi un’espansione. Ciò
              significa che la pressione deve diminuire affinché il gas torni al
              suo stato liquido, completando così il ciclo.
            </p>
            <p>
              Il sistema solare termodinamico proposto utilizza il di fluido
              termovettore R134-A del tutto ecologico in conformità alle norme
              europee, non contenente ammoniaca, non tossico, incombustibile,
              non corrosivo, chimicamente stabile in situazioni di elevata
              pressione ed alte temperature, calore di evaporazione elevato
              senza cloro.
            </p>
            <p>
              <b>
                Il Solare Termodinamico si associa benissimo ad un impianto
                fotovoltaico
              </b>{" "}
              e può essere attivato automaticamente da quest'ultimo in presenza
              di sufficiente energia elettrica prodotta: tale meccanismo,
              denominato <b>ACCUMULO TERMICO</b>, permette di aumentare ancora
              di più la convenienza del fotovoltaico in quanto massimizza
              l'autoconsumo dell'energia prodotta azzerando le spese per la
              produzione di Acqua Calda Sanitaria.
            </p>
          </CardPagina>
        </Col>
        <Col className="text-center mx-auto mt-3" md={4}>
          <img
            className="img-fluid w-70 mx-auto"
            src="termodinamico.png"
            alt="Solare Termodinamico Anzio Nettuno"
          />
          <img
            className="img-fluid mx-auto w-70 mt-3"
            src="energie.jpg"
            alt="Impianti solari e fotovoltaici Lazio"
            title="Impianti solari, fotovoltaici, certificazione energetica Lazio"
          />
          <img
            className="img-fluid w-70 mt-3 mx-auto"
            src="pannello.jpg"
            alt="Pannello Fotovoltaico lazio"
          />
          <img
            className="img-fluid w-70  mt-3 mx-auto"
            src="termodinamico.jpg"
            alt="Termodinamico Anzio Nettuno Aprilia Lazio"
          />
        </Col>
      </Row>
      <CardPagina variante="gridParity text-center">
        Gli unici pannelli solari capaci di captare energia anche con la
        pioggia, di giorno e di notte, estate e inverno!!
        <br />
        <b>DETRAZIONE IRES (aziende) e IRPEF (persone fisiche) del 65%</b>
      </CardPagina>
      <Row>
        <img
          className="img-fluid mt-3 mx-auto"
          src="../solare-termodinamico-lazio.jpg"
          alt="Pannello Fotovoltaico Solare Termodinamico lazio"
        />
        <img
          className="img-fluid  mt-3 mx-auto"
          src="../solare-termodinamico-aprilia.jpg"
          alt="Termodinamico Anzio Nettuno Aprilia Lazio"
        />
      </Row>
      <CardPagina variante="white bordoTop mt-5">
        <Row>
          <Col xs={12} className="mt-3">
            <p className="titoloGrande text-center gridParity">
              Contattaci per un preventivo relativo ad un impianto solare termodinamico in tutto il Lazio<br/>
              Verrai seguito da un team di esperti adatto alle tue esigenze.<br/>
            </p>
          </Col>
          <Col xs={12}>
            <BottoneBianco
              className="mt-3 mx-auto text-center variantePage"
              title="Contattaci ora"
              link="richiesta-informazioni"
            />
          </Col>
        </Row>
      </CardPagina>
    </Container>
  </Layout>
)

export default SolareTermodinamico
